import { calculateItemTotal } from "../../common/price/price";
import { noPaperBagOrderTypes, OptionType } from "../../constants";
import { ActionType } from "../action-types";
import { Action } from "../actions";
import {
  FinalOrderPayload,
  orderItemType,
  preItemType,
} from "./../../Types/Types";
import {
  initialFinalOrderState,
  initialPreItemState,
  initialState,
} from "./utils/initialStates";
import { OrderState } from "./utils/reducerTypes";

const findAndUpdateSameItems = (
  newItem: orderItemType,
  currentOrders: orderItemType[]
) => {
  const customizationJsonString = JSON.stringify(newItem.selectedCustomization);
  const sameCartItem = currentOrders.find((orderItem) => {
    const jsonString = JSON.stringify(orderItem.selectedCustomization);
    const hasSameCustomization = jsonString === customizationJsonString;
    const hasSameName = orderItem.name === newItem.name;
    const hasSameOptionName = orderItem.optionName === newItem.optionName;
    return hasSameName && hasSameOptionName && hasSameCustomization;
  });
  if (sameCartItem) {
    sameCartItem.count++;
    return true;
  }

  return false;
};

const reducer = (
  state: OrderState = initialState,
  action: Action
): OrderState => {
  let newState = state;
  let newCart: orderItemType[] = state.cart;
  let newPreItems: preItemType = state.preItems;
  let newOrderItems: orderItemType[] = state.order.finalOrderItems;
  let newOrder: FinalOrderPayload = state.order;
  let newFinalOrder: FinalOrderPayload = state.finalOrder;

  switch (action.type) {
    case ActionType.ADD_ITEM:
      const payload = action.payload;
      console.log("CURRENT FINAL: ", newOrder);
      console.log("PAYLOAD: ", payload);

      if (payload.count <= 0) return state;

      if (findAndUpdateSameItems(payload, state.cart)) {
        return state;
      }

      newCart.push(payload);
      newOrderItems.push(payload);

      return { ...state, cart: newCart, order: { ...newOrder } };

    case ActionType.PRE_ITEM:
      console.log("payload: ", action.payload);
      newPreItems = { ...action.payload };
      return { ...state, preItems: { ...newPreItems } };

    case ActionType.SET_COUNT_ITEM:
      const orderItem = newCart[action.payload.index];
      orderItem.count = action.payload.count;
      if (orderItem.count < 1) {
        state.cart.splice(action.payload.index, 1);
      }
      return { ...state, cart: newCart };

    case ActionType.INCREMENT_ITEM:
      newCart[action.payload.index].count++;
      return { ...state, cart: newCart };

    case ActionType.DECREMENT_ITEM:
      newCart[action.payload.index].count--;
      if (newCart[action.payload.index].count < 1) {
        state.cart.splice(action.payload.index, 1);
      }
      return { ...state, cart: newCart };

    case ActionType.REMOVE_ITEM:
      console.log("NEW ORDER: ", newCart);
      console.log("NEW FINAL ORDER: ", newOrder);

      const itemToRemove = action.payload;
      const newJson = JSON.stringify(itemToRemove.selectedCustomization);
      const ind = newCart.findIndex((item, i) => {
        if (item.productOptionId !== itemToRemove.productOptionId) {
          return false;
        }

        const cartJson = JSON.stringify(item.selectedCustomization);
        return newJson === cartJson;
      });

      if (ind !== -1) {
        newCart.splice(ind, 1);
      }

      newOrder.finalOrderItems = [...newCart];
      return { ...state, cart: newCart, order: { ...newOrder } };

    case ActionType.SET_INFO_TO_FINAL_ORDER:
      const { isTableViewMode } = action.payload;
      if (isTableViewMode) {
        newOrder.paperBagCount = 0;
      }

      const accummulatedSubTotal = newCart.reduce((acc, cur) => {
        const { itemTotal } = calculateItemTotal(cur);
        return acc + itemTotal;
      }, 0);

      const subTotalWithTax = Math.floor(accummulatedSubTotal);

      const hasOnlyGiftCardsInCart = !newOrder.finalOrderItems.find(
        (x) => x.type !== OptionType.giftCard
      );
      if (
        noPaperBagOrderTypes.includes(newOrder.orderType?.toString() ?? "") ||
        hasOnlyGiftCardsInCart
      ) {
        newOrder.paperBagCount = 0;
      } else {
        newOrder.paperBagCount = 1;
      }

      newOrder.subTotal = subTotalWithTax;

      newOrder.total = Math.floor(subTotalWithTax);

      return { ...state, order: { ...newOrder } };
    case ActionType.SET_COMPLETE_ORDER:
      console.log("BEFORE UPDATING FINAL ORDER:\n", newOrder);
      newOrder = { ...action.payload };
      console.log("ORDER ORDER: ", newCart);
      console.log("NEW FINAL: ", newOrder);
      return { ...state, order: newOrder };

    case ActionType.SET_COMPLETE_FINAL_ORDER:
      console.log("BEFORE UPDATING FINAL ORDER:\n", newFinalOrder);
      newFinalOrder = { ...action.payload };
      console.log("ORDER ORDER: ", newFinalOrder);
      console.log("NEW FINAL: ", newFinalOrder);
      return { ...state, finalOrder: newFinalOrder };

    case ActionType.SET_DISCOUNTS_TO_FINAL_ORDER:
      newOrder.calculatedDiscounts = action.payload;
      newFinalOrder.calculatedDiscounts = action.payload;
      return { ...state, order: newOrder, finalOrder: newFinalOrder };

    case ActionType.SET_MOBILE_DISCOUNTS_TO_FINAL_ORDER:
      newOrder.calculatedMobilePayDiscount = action.payload;
      return { ...state, order: newOrder };

    case ActionType.UPDATE_CUSTOMIZE:
      let index = action.payload.itemIndex ? action.payload.itemIndex : 0;
      let item = newCart[index];

      item.specialRequest =
        index === action.payload.itemIndex
          ? action.payload.specialRequest
          : item.specialRequest;

      item.selectedCustomization.optionSpecialRequest =
        index === action.payload.itemIndex
          ? action.payload.specialRequest
          : item.specialRequest;

      item.selectedCustomization.orderFor =
        index === action.payload.itemIndex
          ? action.payload.orderFor
          : item.selectedCustomization.orderFor;

      return { ...state, cart: newCart };

    case ActionType.CHANGE_ORDER:
      // newFinalOrder.orderNumber = action.payload
      console.log("PAYLOAD: ", action.payload);

      newOrder = { ...action.payload };
      newCart = action.payload.finalOrderItems;
      console.log("NEW FINAL: ", newOrder);
      console.log("NEW ORDER: ", newCart);

      // add tip, email etc

      return {
        ...state,
        cart: [...newCart],
        order: { ...newOrder },
      };

    case ActionType.EMPTY_CART:
      console.log("initialFinalOrderState: ", initialFinalOrderState);

      newState = {
        order: { ...initialFinalOrderState, finalOrderItems: [] },
        finalOrder: { ...initialFinalOrderState, finalOrderItems: [] },
        cart: [],
        preItems: { ...initialPreItemState },
      };
      console.log("New State: ", newState);

      // return newState;
      return { ...newState };

    case ActionType.SET_SPECIAL_NOTES:
      newOrder.instructions = action.payload;
      return { ...state, order: { ...newOrder } };

    case ActionType.SET_ORDER_TYPE:
      const newOrderType = action.payload;

      newOrder.orderType = newOrderType;
      return { ...state, order: { ...newOrder } };

    case ActionType.SET_USER_NAME:
      newOrder.customerName = action.payload;
      return { ...state, order: { ...newOrder } };

    case ActionType.SET_USER_NUMBER:
      newOrder.userPhoneNumber = action.payload;
      return { ...state, order: { ...newOrder } };

    case ActionType.SET_TIP_PERCENTAGE:
      newOrder.selectedTipPercentage = +action.payload;
      return { ...state, order: { ...newOrder } };

    case ActionType.SET_DRIVER_TIP_PERCENTAGE:
      newOrder.driverTipPercentage = +action.payload;
      return { ...state, order: { ...newOrder } };

    case ActionType.SET_PREORDER_DATETIME:
      const dateMilliseconds = action.payload;
      newOrder.requestedPrepDateTime = dateMilliseconds;
      newOrder.requestedPrepDateTimeString = dateMilliseconds
        ? new Date(dateMilliseconds).toJSON()
        : dateMilliseconds?.toString();

      return { ...state, order: { ...newOrder } };

    case ActionType.SET_ORDER_USER_ID:
      newOrder.userId = action.payload.toString();
      return { ...state, order: { ...newOrder } };

    case ActionType.CLEAR_ORDER_USER_ID:
      newOrder.userId = undefined;
      return { ...state, order: { ...newOrder } };

    case ActionType.SET_PAPER_BAG_COUNT:
      newOrder.paperBagCount = action.payload;
      return { ...state, order: { ...newOrder } };

    case ActionType.SET_PAPER_BAG_FEE:
      newOrder.paperBagFee = action.payload;
      return { ...state, order: { ...newOrder } };

    case ActionType.SET_ORDER_MODE:
      newOrder.orderMode = action.payload;
      return { ...state, order: { ...newOrder } };

    case ActionType.SET_SMS_NOTIFICATION_REQUESTED:
      const isRequested = action.payload;
      newOrder.smsNotificationRequested = isRequested;
      return { ...state, order: { ...newOrder } };

    case ActionType.SET_DELIVERY_INFO:
      const deliveryPayload = action.payload;

      return {
        ...state,
        order: { ...newOrder, deliveryAddress: { ...deliveryPayload } },
      };

    case ActionType.SET_ORDER_NUMBER:
      const newOrderNumber = action.payload;
      newOrder.orderNumber = newOrderNumber;
      return { ...state, order: { ...newOrder } };
    default:
      return state;
  }
};

export default reducer;
