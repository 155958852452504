import classes from "./CreditCardPayForm.module.scss";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { calculateDiscountAmt, formatPrice, isNumber, floorPriceAndDivideByHundred, calculateTip } from "../../common/price/price";
import { DISCOUNT_TYPE } from "../../Types/Types";
import { useHistory, useLocation } from "react-router";
import { OrderSubmissionResponse, submitOrder } from "../../api/order";
import { Typography } from "@material-ui/core";
import googleLogo from "../../img/icon-google.png";
import appleLogo from "../../img/icon-apple.png";
import Loader from "../Loader/Loader";
import AppButton from "../AppButton/AppButton";
import usePriceAdjustments from "../ConfirmOrder/hooks/usePriceDetails";
import { Box } from "@mui/material";
import { calculatePaperBagFee } from "../../common/order";
import { useStripe, useElements, CardElement, PaymentElement } from '@stripe/react-stripe-js';
import { PaymentRequest } from "@stripe/stripe-js";
import { useActions } from "../../hooks/useActions";

type lineItemType = {
    amount: string | number,
    label: string,
    pending?: boolean
}

const createLineItem = (amount: string | number, label: string, pending: boolean = false): lineItemType => {
    let amountNum = amount;
    if (isNumber(amount) === false) {
        amountNum = Number(amount)
    }

    return ({
        amount: floorPriceAndDivideByHundred(amountNum).toString(),
        label,
        pending
    });
}

interface CreditCardPayFormProps { total: number; orderNum?: number; }

const StripePaymentForm = (props: CreditCardPayFormProps) => {

    const { total, orderNum } = props;

    const history = useHistory();
    const location = useLocation();
    const stripe = useStripe();
    const elements = useElements();
    const finalOrder = useTypedSelector((state) => state.orderDetails.finalOrder);
    const [errorMessage, setErrorMessage] = useState("");
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setLoading] = useState(false);
    const [paymentError, setPaymentError] = useState("");
    const [paymentMethodId, setPaymentMethodId] = useState<string | null>(null);
    const { businessInfo } = useTypedSelector((state) => state.businessInfo);

    const [paymentRequest, setPaymentRequest] = useState<PaymentRequest>();
    const [isPaymentRequestAvailable, setIsPaymentRequestAvailable] = useState(false);

    // Example cart details
    const cart = [
        { name: "Product 1", price: 1500, quantity: 2 }, // $15.00 x 2
        { name: "Product 2", price: 2500, quantity: 1 }, // $25.00 x 1
    ];

    const handlePaymentMethodSubmission = useCallback(async (event: React.FormEvent) => {
        event.preventDefault();

        console.log('submitting');


        if (!stripe || !elements) {
            setError('Stripe.js has not loaded yet.');
            return;
        }

        if (!elements) {
            setError('elements not found.');
            return;
        }

        try {
            // disable the submit button as we await tokenization and make a payment request.
            setLoading(true);

            const { error } = await stripe.confirmPayment({
                elements,
                redirect: "if_required",
            });

            if (!!error?.message) {
                setPaymentError(error.message);
                return;
            }

            history.push(`/order/${orderNum}`);
        } catch (e: any) {
            setLoading(false);
            console.error(e.message);
        }
    }, [elements, CardElement, stripe])


    return (<>
        <form className={classes.stripeForm}>
            {error && <div style={{ color: 'red' }}>{error}</div>}
            {paymentError && <div style={{ color: 'red' }}>{paymentError}</div>}

            <PaymentElement
                options={{
                    layout: "tabs"
                }}
            />
            <AppButton id="submit" onClick={handlePaymentMethodSubmission}>
                <span id="button-text">
                    Pay now
                </span>
            </AppButton>
        </form>
    </>
    );
}

export default StripePaymentForm;