import classes from "./CreditCardPayForm.module.scss";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { calculateDiscountAmt, formatPrice, isNumber, floorPriceAndDivideByHundred, calculateTip } from "../../common/price/price";
import { DISCOUNT_TYPE } from "../../Types/Types";
import { useHistory } from "react-router";
import { OrderSubmissionResponse, submitOrder } from "../../api/order";
import { Typography } from "@material-ui/core";
import googleLogo from "../../img/icon-google.png";
import appleLogo from "../../img/icon-apple.png";
import Loader from "../Loader/Loader";
import AppButton from "../AppButton/AppButton";
import usePriceAdjustments from "../ConfirmOrder/hooks/usePriceDetails";
import { Box } from "@mui/material";
import { calculatePaperBagFee } from "../../common/order";
import { tryParseJson } from "../../common/common";


type lineItemType = {
    amount: string | number,
    label: string,
    pending?: boolean
}

const createLineItem = (amount: string | number, label: string, pending: boolean = false): lineItemType => {
    let amountNum = amount;
    if (isNumber(amount) === false) {
        amountNum = Number(amount)
    }

    return ({
        amount: floorPriceAndDivideByHundred(amountNum).toString(),
        label,
        pending
    });
}

interface CreditCardPayFormProps { total: number; }

const uuidv4 = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c === 'x' ? r : ((r & 0x3) | 0x8);
        return v.toString(16);
    });
}



const SquarePaymentForm = (props: CreditCardPayFormProps) => {

    const history = useHistory();
    const { total } = props;
    const { businessInfo } = useTypedSelector((state) => state.businessInfo);
    const finalOrder = useTypedSelector((state) => state.orderDetails.finalOrder);
    const [loading, setLoading] = useState(false);
    const [paymentError, setPaymentError] = useState("");
    const [card, setCard] = useState<any>();
    const [applePay, setApplePay] = useState<any>();
    const [googlePay, setGooglePay] = useState<any>();
    const [payments, setPayments] = useState<any>();
    const [cardKey, setCardKey] = useState("");
    const { highestDiscount, surcharges, calculateCreditAmt } = usePriceAdjustments();

    const payKey: { applicationId: string, locationId: string, } | null = useMemo(() => {
        if (!businessInfo?.payKey) return {
            applicationId: "",
            locationId: "",
        };

        
        return tryParseJson(businessInfo.payKey)
    }, [businessInfo?.payKey]);

    const userAgent = useMemo(() => window.navigator.userAgent.toLowerCase(), []);
    const isChrome = useMemo(() => {
        if (userAgent.indexOf('chrome') !== -1) return true;
        return false;
    }, [window.navigator.userAgent]);

    const isSafari = useMemo(() => {
        if (userAgent.indexOf('safari') !== -1 && userAgent.indexOf('chrome') === -1) return true;
        return false;
    }, [window.navigator.userAgent]);

    useEffect(() => {
        let _payments;
        let _card;
        let _googlePay;
        let _applePay;

        (async () => {
            if (!payKey) return;

            const creditCardKey = uuidv4();
            setCardKey(creditCardKey);
            console.log("Init creditCardKey", creditCardKey);

            const Square = (window as any).Square;
            if (!Square) {
                throw new Error('Square.js failed to load properly');
            }

            try {
                _payments = Square.payments(payKey.applicationId, payKey.locationId);
                setPayments(_payments);
            } catch (error) {
                console.error(error);
                return;
            }

            try {
                _card = await initializeCard(_payments);
                setCard(_card);
            } catch (e) {
                console.error('Initializing Card failed', e);
                return;
            }

            try {
                _googlePay = await initializeGooglePay(_payments);
                setGooglePay(_googlePay);
            } catch (e) {
                console.error('Initializing Google Pay failed', e);
            }

            try {
                _applePay = await initializeApplePay(_payments);
                setApplePay(_applePay);
            } catch (e) {
                console.error('Initializing Apple Pay failed', e);
            }

        })()

        return () => {
            try {
                if (_payments)
                    _payments.destroy();
                if (_card)
                    _card.destroy();
                if (_googlePay)
                    _googlePay.destroy();
                if (_applePay)
                    _applePay.destroy();
                setPayments(null);
            } catch (error) {
                console.error(error);
            }
        }
    }, [payKey])


    async function initializeCard(payments) {
        const card = await payments.card({
            // style: darkModeCardStyle,
        });

        await card.attach('#card-container');

        return card;
    }

    async function initializeApplePay(payments) {
        const paymentRequest = buildPaymentRequest(payments);
        const applePay = await payments.applePay(paymentRequest);
        // Note: You do not need to `attach` applePay.
        return applePay;
    }

    async function initializeGooglePay(payments) {
        const paymentRequest = buildPaymentRequest(payments);
        const googlePay = await payments.googlePay(paymentRequest);
        await googlePay.attach('#google-pay-button');

        return googlePay;
    }

    async function tokenize(paymentMethod) {
        const tokenResult = await paymentMethod.tokenize();
        if (tokenResult.status === 'OK') {
            return tokenResult.token;
        } else {
            let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
            if (tokenResult.errors) {
                errorMessage += ` and errors: ${JSON.stringify(
                    tokenResult.errors
                )}`;
            }

            throw new Error(errorMessage);
        }
    }

    const addToList = (value: number, label: string, lineItems: lineItemType[]) => {
        if (!value) return

        const lineItem = createLineItem(value, label);
        lineItems.push(lineItem);
        return lineItem;
    }

    const buildPaymentRequest = (payments) => {
        if (!businessInfo) return;

        const lineItems: lineItemType[] = [];
        addToList(finalOrder.subTotal, "Amount", lineItems);

        addMobilePayDiscount(finalOrder.total, lineItems);
        addOverallDiscounts(finalOrder.total, lineItems);

        addToList(finalOrder.deliveryFee, "Delivery", lineItems);

        const paperBagFee = calculatePaperBagFee(finalOrder);
        addToList(paperBagFee, `Paper Bags (${finalOrder.paperBagCount})`, lineItems);

        addToList(finalOrder.tax, "Tax", lineItems);
        addToList(calculateTip(finalOrder), "Tip", lineItems);
        addToList(finalOrder.total, "Total", lineItems);
        let _finalTotal = addCreditDiscounts(finalOrder.total, lineItems);
        const totalLineItem = addToList(_finalTotal, "Payable Amount", lineItems);

        console.log("Final total:", totalLineItem);
        console.log("Line items: ", lineItems);

        return payments.paymentRequest({
            countryCode: "CA",
            currencyCode: "CAD",
            lineItems,
            total: totalLineItem,
        });
    }

    const overallDiscounts = businessInfo?.overallDiscounts;
    const mobileDiscountTypes = [DISCOUNT_TYPE.credit, DISCOUNT_TYPE.mobilePay];
    const discountsWithAmt = overallDiscounts?.map(dsc => ({
        ...dsc,
        amount: calculateDiscountAmt(dsc, finalOrder.subTotal)
    }));

    const mobileDiscounts = discountsWithAmt?.filter(dsc =>
        dsc.discountType && mobileDiscountTypes.includes(dsc.discountType)
    ).map(dsc => ({
        title: dsc.title,
        type: dsc.type,
        discountType: dsc.discountType,
        value: dsc.value,
        amount: dsc.amount,
    }));

    const addOverallDiscounts = (currentTotal: number, lineItems: lineItemType[]) => {

        const { priceAdjustments } = surcharges;
        priceAdjustments.forEach(dsc => {
            addToList(dsc.amount * -1, dsc.title, lineItems);
            currentTotal -= dsc.amount;
        });

        const discountAmt = highestDiscount?.amount ?? 0;
        if (highestDiscount && discountAmt) {
            addToList(discountAmt * -1, highestDiscount.title, lineItems);
            currentTotal -= discountAmt;
        }
        return currentTotal;
    }


    const addMobilePayDiscount = (currTotal: number, lineItems: lineItemType[]) => {
        if (!mobileDiscounts) return currTotal;

        const mobilePayPriceAdjustments = mobileDiscounts?.filter(dsc => dsc.discountType === DISCOUNT_TYPE.mobilePay)

        if (!mobilePayPriceAdjustments || mobilePayPriceAdjustments.length === 0) return currTotal;

        mobilePayPriceAdjustments.forEach(priceAdjustment => {
            const isTotalLargerThenZero = currTotal - priceAdjustment.amount > 0;
            if (isTotalLargerThenZero) {
                const amt = -1 * priceAdjustment.amount;
                addToList(amt, priceAdjustment.title, lineItems);
                currTotal -= priceAdjustment.amount;
            }
        });

        return currTotal;
    }

    const addCreditDiscounts = (currTotal: number, lineItems: lineItemType[]) => {
        if (!mobileDiscounts) return currTotal;

        const { creditAmtToApply } = calculateCreditAmt(currTotal);
        if (creditAmtToApply === 0) return currTotal;

        const discountDisplayVal = -1 * creditAmtToApply;
        addToList(discountDisplayVal, "Used Store Credit", lineItems);
        return currTotal - creditAmtToApply;
    }


    const handlePaymentMethodSubmission = useCallback(async (event, paymentMethod) => {
        event.preventDefault();

        const ccKey = (paymentMethod !== applePay && paymentMethod !== googlePay) ? cardKey : undefined;

        try {
            // disable the submit button as we await tokenization and make a payment request.
            setLoading(true);
            const token = await tokenize(paymentMethod);
            const submitPromise = submitOrder(finalOrder, {
                total: total,
                nonceToken: token,
                creditCardKey: ccKey,
                businessInfo
            });
            submitPromise.finally(() => setLoading(false));

            const res = await submitPromise as OrderSubmissionResponse;
            if (!res.success) {
                setPaymentError(res as string);
                return;
            }

            if (!res.paymentStatus || res.paymentStatus === "error") {
                const msg = "Sorry, Credit Payment Failed, Please try again or pay in person.";
                setPaymentError(msg);
                return;
            }

            history.push(`/order/${res.orderTrackingId}`);
        } catch (e: any) {
            setLoading(false);
            console.error(e.message);
        }
    }, [card, applePay, googlePay])

    return (<>
        <div className={classes.creditPay}>

            {loading && <Loader />}

            <Box sx={{ margin: '0 24px' }}>
                <Typography color="error">{paymentError}</Typography>
                <form id="creditForm" className={classes.creditForm}>

                    {isChrome && <button
                        id="google-pay-button"
                        className={classes.googlePay}
                        onClick={e => {
                            e.preventDefault();
                            handlePaymentMethodSubmission(e, googlePay);
                        }}
                    >
                        Buy with <img src={googleLogo} className={classes.icon} alt="google logo icon" /> Pay
                    </button>}

                    {isSafari && <button
                        id="apple-pay-button"
                        className={classes.applePay}
                        onClick={e => {
                            e.preventDefault();
                            handlePaymentMethodSubmission(e, applePay);
                        }}
                    >
                        Buy with <img src={appleLogo} className={classes.icon} alt="apple logo icon" /> Pay
                    </button>}

                    <span id="card-container"></span>

                    <AppButton
                        disabled={loading}
                        onClick={(e) => handlePaymentMethodSubmission(e, card)}
                        id="sq-creditcard"
                    >
                        <Typography style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>
                            {!loading ? "Pay with Credit Card " : null}
                        </Typography>
                        &nbsp;
                        <Typography>
                            {!loading ? ` ${formatPrice(total)}` : null}
                        </Typography>

                    </AppButton>
                </form>
            </Box>

        </div>
    </>
    );
}

export default SquarePaymentForm;