import { OptionType, StoreOrderingState } from "../constants";
import { OrderMode } from "./../constants";

export type YesNo = "yes" | "no";
export type Channel = "normal" | "kiosk" | "catering" | "admin" | "menu";

export type DiningOptions = {
  delivery: string;
  dinein: string;
  takeout: string;
  preorder: string;
  dineinDesc?: string;
  takeoutDesc?: string;
  preorderDesc?: string;
  deliveryDesc?: string;
  dineinPrepTimeMinimum?: number;
  takeoutPrepTimeMinimum?: number;
  preorderPrepTimeMinimum?: number;
  deliveryPrepTimeMinimum?: number;
};

export interface BusinessInfo {
  announcement: { message: string; show: string };
  businessId: string;
  customerContactInfo: string;
  customerNameRequired?: boolean;
  diningOption: DiningOptions;
  emailOrderToRestaurant: any;
  env: string;
  excludedCities?: string;
  garageSalePercentage: number;
  menuOnly: boolean;
  mobilePaydiscount: number;
  mobilePaymentDisabled: boolean;
  name: string;
  overallDiscounts: BusinessDiscount[];
  payKey: string;
  paymentGateway: any;
  prepTimeConfig: {
    defaultWaitingMinutes: number;
    incrementPerQueueMinutes: number;
    secondsPerDollar: number;
  };
  productListFilter: boolean;
  serviceProviderUserEmail: string;
  serviceProviderUserId: string;
  specialRequestHint: string;
  squareApplicationId: any;
  squareLocationId: any;
  storeOpen: boolean;
  storeTemporarilyClosed?: boolean;
  storeTemporarilyClosedReason?: string;
  payInPersonDisabled?: boolean;
  storeClosedReason?: string;
  theme: {
    color: string;
    font: string;
    buttonBackgroundColor: string;
    buttonSelectedColor: string;
  };
  logo?: string;
  tip: number[];
  version: number;
  workingHours: WorkingHour[];
  instagramUri?: string;
  location?: Address;
  locations?: Address[];
  facebookUri?: string;
  websiteUri?: string;
  featuredProductImages?: string[];
  reward?: string;
  rewardCriteria?: string;
  tagLine?: string;
  rating?: string;
  secondaryName: string;
  routingPath: string;
  adOn?: boolean;
  menuItemCollapsible?: boolean;
  isMenu?: boolean;
  adHTML?: string;
  creditBalance?: number;
  rewardPoints?: number;
  rewardStampCardType?: number;
  paperBagFee?: number;
  kioskOrderConfirmation?: {
    message: string;
    callToAction: string;
    autoCloseSeconds: number;
    show: YesNo;
  };
  kioskWelcome?: {
    title: string;
    message: string;
    callToAction: string;
    show: YesNo;
  };
  storeOrderingStatus: StoreOrderingState;
  preOrderLimitWarning?: string;
  preOrderPrepMaxDays?: number;
  preOrderPrepMinDays?: number;

  addressLatitude?: string;
  addressLongitude?: string;

  deliveryUnavailableForAddressMessage?: string;
  deliveryDistance?: number;
  deliveryDuration?: number;
  deliveryDistanceInMeters: number;
  deliveryDurationInSeconds: number;

  minOrderAmountForFreeDelivery?: number;
  deliveryPrepTimeMinimum?: number;

  minimumDeliveryFee?: number;
  firstDeliveryKilometersRange?: number;
  tax?: number;
  perExtraKilometerFee?: number;
}

export interface Address {
  addressId: number;
  addressLine1: string;
  addressLine2: string;
  city: string;
  country: string;
  crossCountryUnion: string;
  googleMapUri: string;
  linkId: number;
  postalCode: string;
  province: string;
  state: string;
  suiteNumber: string;
  createDate: string;
  updateDate: string;
}

export type CustomDetailType = {
  id?: string;
  name: string;
  type: string;
  unitOfMeasurement: string;
  title: string;
  value: number;
  sortingOrder: number;
  price: number;
  soldout: boolean;
  externalCustomizationItemId: string;
  externalAccountId: string;
  plu: string;
  customizationItemType: string;
  icon: string;
  multiply: number;
  nestedChoices: CustomizationSection[];
};

export type CustomizationSection = {
  id?: string;
  message: string;
  sectionType: "oneOf" | "anyOf";
  choices: CustomDetailType[];
  externalChoicesId: string;
  sortingOrder: number;
  plu: string;
  min: number;
  max: number;
  multiMax: number;
  multiply: number;
};

export type ProductById = {
  [k: string]: MenuType[];
};

export interface MenuCategory {
  defaultProductImage?: string;
  description?: string;
  id: number;
  name: string;
  selected: boolean;
  serviceProviderUserId: number;
}

export type MenuType = {
  category: number;
  description: string;
  featured: boolean;
  id: number;
  sortingOrder: number;
  image: string;
  name: string;
  options: MenuOption[];
  optionsType: string;
  serviceProviderUserId: number;
  customization: {
    customizationLabel: string;
    optionSpecialRequest: string | null | undefined;
    anyOf: CustomizationSection | null;
    oneOfList: CustomizationSection[] | null;
  };
};

export interface MenuOption {
  customization: {
    customizationLabel: string;
    optionSpecialRequest: string | null | undefined;
    anyOf: CustomizationSection;
    oneOfList: CustomizationSection[];
  };
  discountRule: DiscountRule;
  id: number;
  inventory: any;
  disabled: boolean;
  forceCustomizationBeforeAdd: boolean;
  disabledReason?: string;
  label: string;
  price: number;
  productId: number;
  reloadConfig: any;
  selected: boolean;
  soldout: boolean;
  optionImage: string;
  addCondition?: string;
  type?: OptionType;
  optionDescription: string | null;
}

export interface DiscountRule {
  days: [];
  endDate: string | null;
  endTime: number;
  startDate: string | null;
  startTime: number;
  title: any;
  type: DISCOUNT_VALUE_TYPE;
  value: number;
}

export type MenuByCategory = {
  [k: string]: MenuType[];
};

export type SelectedCustomization = {
  customizationLabel?: string;
  optionSpecialRequest?: string;
  anyOf?: CustomizationSection;
  oneOfList?: CustomizationSection[];
  orderFor?: string;
};

export interface orderItemType {
  count: number;
  selectedCustomization: SelectedCustomization;
  disabled: boolean;
  disabledReason?: string;
  discountRule: DiscountRule;
  name: string;
  image: string;
  description: string;
  optionName: string;
  optionsType: string;
  type?: OptionType;
  price: number;
  productOptionId: number;
  productImage: string | null;
  productDescription: string | null;
  specialRequest: string;
  addCondition?: string;
}

export interface preItemType extends orderItemType {}

export type MenuItem = preItemType | orderItemType;

export type calculatedDiscount = {
  title: string;
  type: DISCOUNT_VALUE_TYPE;
  discountType?: DISCOUNT_TYPE;
  value: number;
  amount: number;
};

export type OrderStatus = {
  businessName: string;
  businessPath: string;
  lastUpdate: string;
  orderActionDateAndTime: string;
  orderActionType: string;
  orderDueTime: string;
  orderPayload: string;
  orderPrepTime: string;
  orderStatus: string;
  stampCardActive: string;
  rejectionReason: string;
  orderNumber: number;
  storeOpen: boolean;
  storeOrderingStatus: StoreOrderingState;
};

export type FinalOrderPayload = {
  businessId: string;
  calculatedDiscounts: calculatedDiscount[];
  calculatedMobilePayDiscount?: calculatedDiscount;
  customerName: string;
  finalOrderItems: orderItemType[];
  instructions: string;
  orderNumber?: string | number;
  orderType?: OrderingType;
  phoneOS: string;
  serviceProviderUserId: string;
  subTotal: number;
  tax: number;
  deliveryFee: number;
  sourceApp: string;
  total: number;
  userPhoneNumber: string;
  smsNotificationRequested: boolean;
  requestedPrepDateTime?: number;
  requestedPrepDateTimeString?: string;
  // requestedPrepTime?: string; // obsolete
  selectedTipPercentage?: number;
  driverTipPercentage?: number;
  userId?: string;
  paperBagFee?: number;
  paperBagCount?: number;
  orderMode?: OrderMode;
  deliveryAddress: DeliveryInfo;
};

export type OrderingType =
  | "dine-in"
  | "take-out"
  | "pre-order"
  | "delivery"
  | number;

export type itemCount = {
  index: number;
  count: number;
};

export type NumbersList = {
  id?: string;
  values: Array<number>;
};

export type customizationType = {
  choicesBySection: SectionChoicesById;
  choicesById: ChoicesById;
  specialRequest: string;
  itemIndex?: number | undefined;
  orderFor?: string;
};

export type ChoiceValues = {
  choice: CustomDetailType;
  value: number;
};

export type ChoicesById = {
  [key: string]: ChoiceValues;
} & {
  section?: CustomizationSection;
  parentChoiceId?: string;
};

export type SectionChoicesById = {
  [key: string]: ChoicesById;
};

export type TrackOrderPayload = {
  token: string | null;
  order: {
    businessId: string | number | undefined;
    finalOrderItems: any[];
    instructions: string | null;
    orderNumber: string | number | null;
    orderType?: OrderingType;
    phoneOS: string | null;
    selectedTipPercentage?: number;
    serviceProviderUserId: string | number;
    subTotal: number;
    tax: number;
    total: number;
    userPhoneNumber: string | number | null;
    userName?: string | number | null;
  };
};

export type finalOrderItems = {
  price: number;
  name: string;
  optionName: string;
  optionsType: string;
  count: number;
  productOptionId?: string | null;
};

export type TrackOrderInfo = {
  orderNumber: string | number;
  orderPrepTime: number;
  paymentStatus: string;
  requestPayload: TrackOrderPayload;
  success: boolean;
  timestamp: string;
};

export type UpdateStatus = {
  orderStatus: string;
  orderPrepTime: string;
  lastUpdate: string;
  orderPayload: string;
  businessName: string;
};

export type receiptPayload = {
  orderNumber: string | number;
  emailId?: string;
  smsNUmber?: string | number;
  rewardsProgram: string;
};

export type credentialPayload = {
  signInId: string;
  password: string;
};

export type BusinessDiscount = {
  type: DISCOUNT_VALUE_TYPE;
  value: number;
  title: string;
  discountType?: DISCOUNT_TYPE;
  startTime?: number;
  endTime?: number;
  days?: string[];
  startDate?: string;
  endDate?: string;
  minPurchase?: number;
  maxPurchase?: number;
  redeemLimit?: number;
  amount?: number;
};

export enum DISCOUNT_VALUE_TYPE {
  percentage = "percentage",
  amount = "amount",
  bogo = "bogo",
}
export enum DISCOUNT_TYPE {
  all = "All",
  mobilePay = "mobilePay",
  credit = "credit",
  promoCode = "promoCode",
  "dine-in" = "dine-in",
  "take-out" = "take-out",
  "pre-order" = "pre-order",
}

export interface WorkingHour {
  days: string;
  startTime: number;
  endTime: number;
  open: boolean;
}

export interface DateOption {
  date: Date;
  workingHour: WorkingHour;
}

export interface DateTimeOption extends DateOption {
  timeOptions: Time[];
}

export interface Time {
  hour: number;
  minute: number;
}

export interface DestinationInfo {
  destinationStatus: DestinationStatus;
  distance?: number;
  duration?: number;
  latitude?: number;
  longitude?: number;
}

export interface DeliveryInfo extends DestinationInfo {
  completeAddress?: string;
  unitNumber?: string;
  deliveryInstruction?: string;
  addressComponents?: google.maps.GeocoderAddressComponent[];
}

export enum DestinationStatus {
  standby,
  error,
  unavailable,
  valid,
}

export enum CheckoutFormStatus {
  standby,
  missingFields,
  deliveryError,
  valid,
}

export type DeliveryCookie = {
  deliveryAddress: DeliveryInfo;
  location: google.maps.LatLng;
};
