import { useEffect, useMemo, useState } from "react";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import SquarePaymentForm from "./SquarePaymentForm";
import StripePaymentForm from "./StripePaymentForm";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { OrderSubmissionResponse, submitOrder } from "../../api/order";
import { useActions } from "../../hooks/useActions";


interface CreditCardPayFormProps { total: number; }


const CreditCardPayForm = (props: CreditCardPayFormProps) => {
  const { setOrderNumber } = useActions();
  const finalOrder = useTypedSelector((state) => state.orderDetails.finalOrder);
  const { businessInfo } = useTypedSelector((state) => state.businessInfo);
  const [clientSecret, setClientSecret] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [orderNum, setOrderNum] = useState<number>();

  const stripePromise = useMemo(() => {
    if (!businessInfo?.payKey)
      throw Error("payKey is not provided")

    return loadStripe(businessInfo?.payKey); // Replace with your Stripe publishable key
  }, [businessInfo?.payKey])

  useEffect(() => {

    const getClientSecret = async () => {

      if (businessInfo?.paymentGateway === "square") {
        return;
      }

      const submitPromise = submitOrder(finalOrder, {
        total: props.total,
        nonceToken: "6667de8ba6a3f864a90f9690",
        businessInfo
      });

      submitPromise.finally(() => setLoading(false));

      const res = await submitPromise as OrderSubmissionResponse;
      if (!res.success) {
        return;
      }

      if (!res.paymentStatus || res.paymentStatus === "error") {
        return;
      }

      if (!res.authorizationResult) {
        return;
      }

      setClientSecret(res.authorizationResult);
      setOrderNum(res.orderTrackingId)
      setOrderNumber(res.orderNumber!);
    }

    getClientSecret();

  }, [businessInfo?.paymentGateway, props.total, finalOrder]);

  switch (businessInfo?.paymentGateway) {
    case "stripe":
      return clientSecret ? (
        <Elements stripe={stripePromise} options={{
          clientSecret: clientSecret,
          loader: "auto",
          // amount: props.total,
          // currency: 'cad',
          // Customizable with appearance API.
          appearance: {/*...*/ },
        }
        } >
          <StripePaymentForm
            total={props.total}
            orderNum={orderNum} />
        </Elements >
      ) : null
    case "square":
      return <SquarePaymentForm total={props.total} />
    default:
      return <></>
  }
}

export default CreditCardPayForm;